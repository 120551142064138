.site-layout-content {
  min-height: 280px;
  padding: 4em;
  background: #3E5151;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #DECBA4, #3E5151);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #DECBA4, #3E5151); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.menu-icon {
  display:none;
}

.menu-text {
  display: inline;
}

@media only screen and (max-width: 740px) {
  .ant-menu-item {
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 640px) { 
  .ant-menu {
  margin: -5px 0 0 -10px !important;
  }

  .ant-menu-item {
    padding-right: 5px !important;
  }
  
  .ant-menu-item-selected {
    background-color: #284B63 !important;
  }

  .menu-icon{
    display: inline !important;
  }

  .menu-item {
    font-size: 0.75em;
  }

  .menu-text {
    display: none;
  }

  .site-layout-content {
    padding: 1em;
  }
} 