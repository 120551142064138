body {
    line-height: 1.8;
  }
  
.bio {
    color:#284B63;
    font-size: 1.75em;
    margin-left: 150px;
    margin-right: 150px;
  }

.container {
  background-color: #ececec;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top: 20px;
}

.heading h1 {
    font-size: 3.25em;
    text-align: center;
    color: #284B63; 
  }

.heading h2 {
  font-size: 3em;
  text-align: center;
  color: white; 
  margin-top: 30px;
}

.heading h3 {
  font-size: 2.75em;
  text-align: center;
  color: white; 
}

@media only screen and (max-width: 1007px){
  .bio {
    font-size: 1.25em;
    margin-left: 100px;
    margin-right: 100px;
  }
  .heading h1 {
    font-size: 2.75em;
  }
}

@media only screen and (max-width: 640px){
  .bio {
    font-size: 1em;  
    margin-left: 20px;
    margin-right: 20px;
  }
  .heading h1 {
    font-size: 1.5em;
  }
}
