.contact-details {
    text-align: center;
    color:#284B63;
    font-size: 1.25em;
    margin-bottom: 15px;
}

.contact-heading {
    text-align: center;
    color:#284B63;
    font-size: 2.5em;
    margin-bottom: 10px;
}

.form {
    background-color: #ececec;
    display: flex;
    justify-content: center;
    padding: 20px 10px 10px 10px !important;
    margin-top: 20px;
}

.messages {
    color: black !important;
}