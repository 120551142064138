h1 {
  color: black;
}

.card-container{
  background-color: #ececec;
    display: grid;
    grid-template-columns: 45% 45%;
    margin-top: 20px;
    grid-gap: 20px;
    justify-content: center;
    grid-template-areas:
    "card-left card-right"
    "card-bleft card-bright";
}

.card-desc{
  margin: 2px; 
  text-align: center; 
  font-size: 1em;
}

.card-left{
  grid-area: card-left;
}
.card-right{
  grid-area: card-right;
}
.card-bleft{
  grid-area: card-bleft;
}
.card-right{
  grid-area: card-bright;
}

.card-left, .card-right, .card-bleft, .card-bright{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.project-icon{
  font-size: 3em;
  margin: 25px 25px;
}

.site-card-wrapper {
  padding: 30px;
  background: #ececec;
  margin: 3em;
}

@media only screen and (max-width: 1025px) {
  .card-container{
    grid-template-columns: 100%;
    padding-bottom: 0.75em;
    grid-template-areas:
    "card-left"
    "card-right"
    "card-bleft"
    "card-bright";
  }
  #dir-app {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .card-container {
    font-size: 0.5em !important;
  }
  .cards{
    margin: 1em !important;
  }
  .img {
    height: 15em !important;
  }
}